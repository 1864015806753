<template>
  <v-row class="d-flex justify-center mt-0 mx-md-2 mx-n1 mb-0">
    <v-col cols="12" class="d-flex justify-end align-center pt-0 px-1">
      <div
        v-text="nuevo ? 'Alta de proveedor' : `Proveedor: ${proveedor_copy.codigo} - ${proveedor_copy.nombre}`"
        style="font-size: 20px"
      ></div>
      <v-spacer></v-spacer>
      <v-expand-x-transition>
        <v-switch
          v-if="!nuevo && existe"
          v-model="editar"
          label="Editar"
          class="d-flex align-center py-0 my-0"
          hide-details
          dense
        ></v-switch>
      </v-expand-x-transition>
    </v-col>
    <v-card>
      <v-col cols="12">
        <v-form ref="form">
          <v-row class="pt-4">
            <template v-if="!nuevo">
              <v-col cols="6" sm="3" md="2" class="py-0">
                <v-text-field
                  v-model="proveedor.codigo"
                  label="Código"
                  type="number"
                  outlined
                  readonly
                  filled
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="6" sm="3" md="2" class="py-0">
                <v-text-field
                  v-model="proveedor.fecha"
                  label="Alta"
                  outlined
                  readonly
                  filled
                  dense
                ></v-text-field>
              </v-col>
              <v-slide-x-transition>
                <v-col
                  v-if="proveedor.inhabilitado == 1 && proveedor.baja"
                  cols="6" sm="3" md="2"
                  class="py-0"
                >
                  <v-text-field
                    v-model="proveedor.baja"
                    label="Baja"
                    outlined
                    readonly
                    filled
                    dense
                  ></v-text-field>
                </v-col>
              </v-slide-x-transition>
              <v-col cols="6" sm="3" md="5" class="py-sm-0 pt-0 pb-8">
                <v-switch
                  v-model="proveedor.inhabilitado"
                  label="Inhabilitado"
                  class="my-0"
                  :readonly="!editar"
                  :true-value="1"
                  :false-value="0"
                  hide-details
                  dense
                  @click="editar ? set_baja() : ''"
                ></v-switch>
              </v-col>
            </template>
            <v-col cols="12" sm="4" class="py-0">
              <v-text-field
                v-model="proveedor.nombre"
                label="Nombre"
                :rules="editar ? [rules.required] : []"
                :autofocus="editar"
                :readonly="!editar"
                :filled="!editar"
                validate-on-blur
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="6" sm="4" md="2" class="py-0">
              <v-text-field
                v-model="proveedor.cuit"
                label="CUIT"
                type="number"
                :rules="editar ? [rules.required] : []"
                :readonly="!(editar && nuevo)"
                :filled="!(editar && nuevo)"
                validate-on-blur
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="6" sm="4" md="3" class="py-0">
              <v-autocomplete
                v-model="proveedor.rubro"
                label="Rubro"
                item-text="nombre"
                item-value="codigo"
                :items="rubros"
                :rules="editar ? [rules.required] : []"
                :readonly="!editar"
                :filled="!editar"
                validate-on-blur
                outlined
                dense
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="4" md="3" class="py-0">
              <v-autocomplete
                v-model="proveedor.iva"
                label="Condición IVA"
                item-text="nombre"
                item-value="codigo"
                :items="condiciones_iva"
                :rules="editar ? [rules.required] : []"
                :readonly="!editar"
                :filled="!editar"
                validate-on-blur
                outlined
                dense
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="4" md="3" class="py-0">
              <v-autocomplete
                v-model="proveedor.provincia"
                label="Provincia"
                item-text="nombre"
                item-value="codigo"
                :items="provincias"
                :rules="editar ? [rules.required] : []"
                :readonly="!editar"
                :filled="!editar"
                validate-on-blur
                outlined
                dense
                @change="get_localidades(); proveedor.localidad = null"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="4" md="5" class="py-0">
              <v-autocomplete
                v-model="proveedor.localidad"
                label="Localidad"
                item-text="nombre"
                item-value="codigo"
                :items="localidades"
                :rules="editar ? [rules.required] : []"
                :readonly="!editar"
                :filled="!editar"
                validate-on-blur
                outlined
                dense
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="4" class="py-0">
              <v-text-field
                v-model="proveedor.domicilio"
                label="Domicilio"
                :rules="editar ? [rules.required] : []"
                :readonly="!editar"
                :filled="!editar"
                validate-on-blur
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4" md="3" class="py-0">
              <v-text-field
                v-model="proveedor.telefono"
                label="Teléfono"
                :readonly="!editar"
                :filled="!editar"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4" class="py-0">
              <v-text-field
                v-model="proveedor.email"
                label="Email"
                :rules="editar ? [rules.email] : []"
                :readonly="!editar"
                :filled="!editar"
                validate-on-blur
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" class="py-0">
              <v-card outlined>
                <v-card-title class="body-1 pt-2">
                  Configuración
                </v-card-title>
                <v-card-text class="py-0 mt-2 mb-sm-4 mb-6">
                  <v-row>
                    <v-col cols="12" sm="3" class="py-1">
                      <v-checkbox
                        v-model="proveedor.prov_base"
                        label="Proveedor base"
                        :true-value="1"
                        :false-value="null"
                        :readonly="!editar"
                        hide-details
                        dense
                        @change="proveedores_asociados = []"
                      ></v-checkbox>
                    </v-col>
                    <v-col cols="12" sm="9" class="py-1">
                      <v-autocomplete
                        v-model="proveedores_asociados"
                        label="Proveedores asociados"
                        :items="proveedores"
                        :clearable="editar && proveedor.prov_base == 1"
                        :deletable-chips="editar && proveedor.prov_base == 1"
                        :readonly="!(editar && proveedor.prov_base == 1)"
                        :filled="!(editar && proveedor.prov_base == 1)"
                        :rules="editar && proveedor.prov_base == 1 ? [rules.required_array] : []"
                        validate-on-blur
                        small-chips
                        multiple
                        outlined
                        dense
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="4" md="3" class="py-1 mt-sm-n2">
                      <v-checkbox
                        v-model="proveedor.codigo_barra"
                        label="Código de barra propio"
                        :true-value="1"
                        :false-value="null"
                        :readonly="!editar"
                        hide-details
                        dense
                      ></v-checkbox>
                    </v-col>
                    <v-col cols="12" sm="4" class="py-1 mt-sm-n2">
                      <v-checkbox
                        v-model="proveedor.factura_recep"
                        label="Factura múltiples recepciones"
                        :true-value="1"
                        :false-value="null"
                        :readonly="!editar"
                        hide-details
                        dense
                      ></v-checkbox>
                    </v-col>
                    <v-col cols="12" sm="4" class="py-1 mt-sm-n2">
                      <v-checkbox
                        v-model="proveedor.arts_venta"
                        label="Prov. de artículos para venta"
                        :true-value="1"
                        :false-value="null"
                        :readonly="!editar"
                        hide-details
                        dense
                      ></v-checkbox>
                    </v-col>
                    <v-col cols="12" sm="4" class="pt-6">
                      <v-autocomplete
                        v-model="proveedor.metodo_busqueda"
                        label="Método de búsqueda"
                        item-text="nombre"
                        item-value="codigo"
                        :items="metodos"
                        :clearable="editar"
                        :readonly="!editar"
                        :filled="!editar"
                        hide-details
                        outlined
                        dense
                      ></v-autocomplete>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" class="">
              <v-card outlined>
                <v-card-title class="body-1 pt-2">
                  Contacto comercial
                </v-card-title>
                <v-card-text class="py-0 mt-2 mb-sm-1 mb-2">
                  <v-row>
                    <v-col cols="12" sm="4" class="py-0">
                      <v-text-field
                        v-model="proveedor.contacto"
                        label="Nombre"
                        :readonly="!editar"
                        :filled="!editar"
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="4" md="3" class="py-0">
                      <v-text-field
                        v-model="proveedor.contacto_telefono"
                        label="Teléfono"
                        :readonly="!editar"
                        :filled="!editar"
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="4" class="py-0">
                      <v-text-field
                        v-model="proveedor.contacto_email"
                        label="Email"
                        :rules="editar ? [rules.email] : []"
                        :readonly="!editar"
                        :filled="!editar"
                        validate-on-blur
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-title class="body-1 pt-2">
                  Contacto administrativo
                </v-card-title>
                <v-card-text class="py-0 mt-2 mb-sm-1 mb-2">
                  <v-row>
                    <v-col cols="12" sm="4" class="py-0">
                      <v-text-field
                        v-model="proveedor.contacto_adm"
                        label="Nombre"
                        :readonly="!editar"
                        :filled="!editar"
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="4" md="3" class="py-0">
                      <v-text-field
                        v-model="proveedor.contacto_adm_telefono"
                        label="Teléfono"
                        :readonly="!editar"
                        :filled="!editar"
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="4" class="py-0">
                      <v-text-field
                        v-model="proveedor.contacto_adm_email"
                        label="Email"
                        :rules="editar ? [rules.email] : []"
                        :readonly="!editar"
                        :filled="!editar"
                        validate-on-blur
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-form>
      </v-col>
    </v-card>
    <v-row class="d-flex justify-end pr-3 my-3">
      <v-btn
        class="ml-3 mt-2"
        :color="editar ? 'error' : 'secondary'"
        @click="cerrar()"
      >
        {{ editar ? 'Cancelar' : 'Volver' }}
      </v-btn>
      <BtnConfirmar
        v-if="editar"
        clase="ml-3 mt-2"
        @action="guardar()"
      />
    </v-row>
  </v-row>
</template>

<script>
import moment from 'moment'
import { order_list_by, REGEX_EMAIL } from '../../util/utils'
import BtnConfirmar from '../../components/util/BtnConfirmar'

export default {
  data () {
    return {
      id: this.$route.params.id,
      nuevo: false,
      editar: false,
      existe: false,
      rubros: [],
      metodos: [],
      provincias: [],
      localidades: [],
      proveedores: [],
      condiciones_iva: [],
      proveedores_asociados: [],
      proveedor_copy: {},
      proveedor: {
        codigo: null,
        fecha: null,
        baja: null,
        inhabilitado: 0,
        nombre: null,
        cuit: null,
        domicilio: null,
        localidad: null,
        provincia: null,
        rubro: null,
        iva: null,
        telefono: null,
        email: null,
        contacto: null,
        contacto_telefono: null,
        contacto_email: null,
        contacto_adm: null,
        contacto_adm_telefono: null,
        contacto_adm_email: null,
        arts_venta: null,
        codigo_barra: null,
        factura_recep: null,
        metodo_busqueda: null,
        prov_base: null,
        asociados: []
      },
      rules: {
        required: value => !!value || 'Campo requerido',
        required_array: value => {
          if (Array.isArray(value) && value.length > 0) return true
          else return 'Campo requerido'
        },
        email: value => {
          if (value) return REGEX_EMAIL.test(value) || 'Email no válido'
          else return true
        }
      }
    }
  },
  async created () {
    if (this.id) {
      await this.get_data()
      await this.get_proveedor()
      await this.get_proveedores()
      await this.get_localidades()
    } else {
      this.editar = true
      this.nuevo = true
      await this.get_data()
      await this.get_proveedores()
    }
  },
  components: {
    BtnConfirmar
  },
  watch: {
    async editar (val) {
      if (val) {
        this.$refs.form.resetValidation()
      } else {
        // hace una copia fiel de la provincia antes de volver a como estaba el proveedor
        const provincia = JSON.parse(JSON.stringify(this.proveedor.provincia))
        // vuelve a dejar los datos del proveedor como estaban cuando hizo la consulta
        this.proveedor = await JSON.parse(JSON.stringify(this.proveedor_copy))
        this.proveedores_asociados = [...this.proveedor.asociados]
        // solo si cambio la provincia vuelve a traer las localidades de la provincia original
        if (provincia != this.proveedor_copy.provincia) {
          await this.get_localidades()
        }
      }
    }
  },
  methods: {
    async guardar () {
      if (this.$refs.form.validate()) {
        this.proveedor.asociados = [...this.proveedores_asociados]
        this.$store.state.loading = true
        if (this.nuevo) {
          await this.$store.dispatch('proveedores/nuevo', this.proveedor)
            .then((res) => {
              this.$store.dispatch('show_snackbar', {
                text: res.message,
                color: 'success',
              })
              this.refrescar_datos()
            })
            .catch(error => {
              this.$store.dispatch('show_snackbar', {
                text: error.message,
                color: 'error',
              })
            })
        } else {
          // solo si realmente cambio algun valor actualiza
          if (JSON.stringify(this.proveedor) != JSON.stringify(this.proveedor_copy)) {
            await this.$store.dispatch('proveedores/editar', this.proveedor)
              .then((res) => {
                this.$store.dispatch('show_snackbar', {
                  text: res.message,
                  color: 'success',
                })
                this.refrescar_datos()
              })
              .catch(error => {
                this.$store.dispatch('show_snackbar', {
                  text: error.message,
                  color: 'error',
                })
              })
          } else {
            this.$store.dispatch('show_snackbar', {
              text: 'No se realizaron cambios en el proveedor: los campos nuevos son iguales a los anteriores',
              color: 'warning',
              timeout: 4000
            })
          }
        }
        this.$store.state.loading = false
      }
    },
    async get_proveedores () {
      this.proveedores = []

      this.$store.state.loading = true
      let result = await this.$store.dispatch('proveedores/get_prov_asociar', this.id)
      this.$store.state.loading = false

      if (result.exito == 1) {
        this.proveedores = result.data
        // agrego los proveedores asociados del proveedor q esta consulta no me los trae
        for (const proveedor of this.proveedores_asociados) {
          let existe = this.proveedores.find(pv => pv.value == proveedor.value)
          if (!existe) {
            this.proveedores.push(proveedor)
          }
        }
        order_list_by(this.proveedores, 'text')
      } else {
        this.$store.dispatch('show_snackbar', {
          text: result.message,
          color: 'error'
        })
      }
    },
    async get_proveedor () {
      this.$store.state.loading = true
      await this.$store.dispatch('proveedores/get_proveedor', this.id)
        .then((res) => {
          this.proveedor = res.data
          this.proveedor_copy = JSON.parse(JSON.stringify(this.proveedor))
          this.proveedores_asociados = [...this.proveedor.asociados]
          this.existe = true
        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error',
          })
        })
      this.$store.state.loading = false
    },
    async get_localidades () {
      this.localidades = []
      const provincia = this.proveedor.provincia
      if (provincia) {
        this.$store.state.loading = true
        await this.$store.dispatch('genericosAcc/get_localidades', provincia)
          .then((res) => {
            this.localidades = res.data
          })
          .catch(error => {
            this.$store.dispatch('show_snackbar', {
              text: error.message,
              color: 'error',
            })
          })
        this.$store.state.loading = false
      }
    },
    async get_data () {
      this.rubros = []
      this.metodos = []
      this.provincias = []
      this.condiciones_iva = []
      this.$store.state.loading = true
      await this.$store.dispatch('proveedores/get_rubros')
        .then((res) => {
          this.rubros = res.data
        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error',
          })
        })
      await this.$store.dispatch('proveedores/get_metodos')
        .then((res) => {
          this.metodos = res.data
        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error',
          })
        })
      await this.$store.dispatch('genericosAcc/get_provincias')
        .then((res) => {
          this.provincias = res.data
        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error',
          })
        })
      await this.$store.dispatch('genericosAcc/get_condiciones_iva')
        .then((res) => {
          this.condiciones_iva = res.data
        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error',
          })
        })
      this.$store.state.loading = false
    },
    refrescar_datos () {
      // actualiza los datos del proveedor en la vista y bloquea la edicion
      this.proveedor_copy = JSON.parse(JSON.stringify(this.proveedor))
      this.editar = false
    },
    set_baja () {
      // solo si el proveedor estaba habilitado setea la fecha de hoy como la fecha de baja
      if (this.proveedor_copy.inhabilitado == 0) {
        this.proveedor.baja = moment(new Date()).format('DD/MM/YYYY')
      }
    },
    cerrar () {
      window.close()
    }
  }
}
</script>
