<template>
  <v-row class="d-flex justify-center mt-0">
    <v-col cols="12">
      <v-expansion-panels v-model="panel">
        <v-expansion-panel readonly>
          <v-btn
            style="position: absolute; top: -15px; right: 55px; z-index: 1"
            color="success"
            small
            fab
            @click="open_window(null)"
          >
            <v-icon>fas fa-plus</v-icon>
          </v-btn>
          <v-expansion-panel-header class="py-0 px-4 text-h5" style="cursor: default">
            <div>
              <v-icon class="mb-1" left>fas fa-filter</v-icon>
              Filtro
            </div>
            <template v-slot:actions>
              <v-btn icon @click.stop="panel = panel === 1 ? 0 : 1">
                <v-icon>fas fa-chevron-down</v-icon>
              </v-btn>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="pt-1">
            <v-form @submit.prevent="buscar()">
              <v-row :no-gutters="$vuetify.breakpoint.xs">
                <v-col cols="12" sm="6" md="2" class="py-1">
                  Código
                  <v-text-field
                    v-model.trim="filtro.codigo"
                    tabindex="1"
                    type="number"
                    hide-details
                    autofocus
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="3" class="py-1">
                  Nombre
                  <v-text-field
                    v-model.trim="filtro.nombre"
                    tabindex="1"
                    hide-details
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="2" class="py-1">
                  CUIT
                  <v-text-field
                    v-model.trim="filtro.cuit"
                    tabindex="1"
                    type="number"
                    hide-details
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="3" class="py-1">
                  Rubro
                  <v-autocomplete
                    v-model="filtro.rubro"
                    item-text="nombre"
                    item-value="codigo"
                    tabindex="1"
                    :items="rubros"
                    hide-details
                    clearable
                    outlined
                    dense
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" md="3" class="py-1">
                  Contacto
                  <v-text-field
                    v-model.trim="filtro.contacto"
                    tabindex="1"
                    hide-details
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="6" sm="2" md="2" class="py-1">
                  <v-checkbox
                    v-model="filtro.inhabilitado"
                    label="Inhabilitado"
                    class="pt-6"
                    :true-value="1"
                    :false-value="0"
                    hide-details
                    dense
                  ></v-checkbox>
                </v-col>
                <v-col cols="12" sm="2" md="2" class="py-1">
                  <v-checkbox
                    v-model="filtro.proveedor_venta"
                    label="Artículos Venta"
                    class="pt-6"
                    :true-value="1"
                    :false-value="0"
                    hide-details
                    dense
                  ></v-checkbox>
                </v-col>
                <BtnFiltro
                  :loading="load"
                  @clear="limpiar()"
                />
              </v-row>
            </v-form>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      
      <v-data-table
        class="cebra elevation-2 mt-2"
        :headers="headers"
        :items="proveedores"
        :loading="load"
        :search="search"
        sort-by="nombre"
        dense
      >
        <template v-slot:top>
          <v-row class="d-flex justify-end pa-2" no-gutters>
            <v-col cols="6" sm="3" md="2">
              <SearchDataTable
                v-model="search"
              />
            </v-col>
          </v-row>
        </template>
        <template v-slot:[`item.inhabilitado`]="{ item }">
          <span
            v-text="item.inhabilitado ? 'SI' : 'NO'"
          ></span>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon
            color="info"
            title="Ver"
            class="mr-2"
            small
            @click="open_window(item.codigo)"
          >
            fas fa-eye
          </v-icon>
          <BtnConfirmar
            :icono="item.inhabilitado ? 'fas fa-check' : 'fas fa-ban'"
            :color="item.inhabilitado ? 'success' : 'error'"
            :title="item.inhabilitado ? 'Habilitar' : 'Inhabilitar'"
            :texto="item.inhabilitado
              ? `¿Desea habilitar al proveedor <strong>${item.nombre}</strong>?`
              : `¿Desea inhabilitar al proveedor <strong>${item.nombre}</strong>?`"
            :icon_button="true"
            :small="true"
            @action="item.inhabilitado ? habilitar(item) : inhabilitar(item)"
          />
        </template>
        <template v-slot:no-data>
          <v-alert
            class="mx-auto mt-4"
            max-width="400"
            type="warning"
            border="left"
            dense
            text
          >
            No hay datos para los filtros seleccionados
          </v-alert>
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>

<script>
import BtnFiltro from '../../components/util/BtnFiltro'
import BtnConfirmar from '../../components/util/BtnConfirmar'
import SearchDataTable from '../../components/util/SearchDataTable'

export default {
  data () {
    return {
      panel: 0,
      search: '',
      load: false,
      rubros: [],
      proveedores: [],
      headers: [
        { text: 'Código', value: 'codigo', align: 'end' },
        { text: 'Nombre', value: 'nombre' },
        { text: 'CUIT', value: 'cuit', align: 'end' },
        { text: 'Rubro', value: 'rubro' },
        { text: 'Contacto', value: 'contacto' },
        { text: 'Acciones', value: 'actions', sortable: false, filterable: false },
      ],
      filtro: {
        codigo: null,
        nombre: '',
        cuit: null,
        rubro: null,
        contacto: '',
        inhabilitado: 0,
        proveedor_venta: 0
      }
    }
  },
  async created () {
    this.$store.state.loading = true
    this.rubros = []
    await this.$store.dispatch('proveedores/get_rubros')
      .then((res) => {
        this.rubros = res.data
      })
      .catch(error => {
        this.$store.dispatch('show_snackbar', {
          text: error.message,
          color: 'error',
        })
      })
    this.$store.state.loading = false
  },
  components: {
    BtnFiltro,
    BtnConfirmar,
    SearchDataTable
  },
  methods: {
    async habilitar (item) {
      this.$store.state.loading = true
      await this.$store.dispatch('proveedores/habilitar', item.codigo)
        .then((res) => {
          // elimina al proveedor de la lista
          const index = this.proveedores.indexOf(item)
          this.proveedores.splice(index, 1)
          // muestra mensaje
          this.$store.dispatch('show_snackbar', {
            text: res.message,
            color: 'success',
          })
        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error',
          })
        })
      this.$store.state.loading = false
    },
    async inhabilitar (item) {
      this.$store.state.loading = true
      await this.$store.dispatch('proveedores/inhabilitar', item.codigo)
        .then((res) => {
          // elimina al proveedor de la lista
          const index = this.proveedores.indexOf(item)
          this.proveedores.splice(index, 1)
          // muestra mensaje
          this.$store.dispatch('show_snackbar', {
            text: res.message,
            color: 'success',
          })
        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error',
          })
        })
      this.$store.state.loading = false
    },
    async buscar () {
      this.load = true
      this.search = ''
      this.proveedores = []
      await this.$store.dispatch('proveedores/get_proveedores', this.filtro)
        .then((res) => {
          this.proveedores = res.data
        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error',
          })
        })
      this.load = false
    },
    open_window (codigo) {
      let path = '/proveedor'
      if (codigo) {
        path = `${path}/${codigo}`
      }
      const routeData = this.$router.resolve({ path: path })
      window.open(routeData.href, '_blank')
    },
    limpiar () {
      this.filtro = {
        codigo: null,
        nombre: '',
        cuit: null,
        rubro: null,
        contacto: '',
        inhabilitado: 0
      }
    }
  }
}
</script>